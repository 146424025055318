import axios from 'axios';
let baseUrl = '/zhcwaterweb';
var qs = require('qs');  

//获取多条数据
export const getCompanyList = params=>{
    let pm=qs.stringify(params);
    return axios.post(`${baseUrl}/mweb/gsBaseSet/getListBase`,pm);
}

//修改
export const getEditCompany = params=>{
    let pm=qs.stringify(params);    
    return axios.post(`${baseUrl}/mweb/gsBaseSet/updateBase`,pm)
}
