<template>
    <div>        
    <section class='trbaner'>
        <div class="foxj">
            <div class='am-container'>
                <div class='txt'>
                    <div class="am-vertical-align-middle">
                     <p class="tit">产品和技术是我们发展的根基</p>
                     <p class="note">以水务行业信息化管理系统软硬件产品为基础，以快速为客户提供个性化的解决方案为主要经营模式实现企业价值与客户价值共同成长。</p>
                </div>
                </div>
            </div>
        </div>
        <img src='~images/产品中心.png'>
    </section>
    <section class="am-path">
        <div class="am-container">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/product' }">产品中心</el-breadcrumb-item>
            <el-breadcrumb-item >{{this.product.product_name}}</el-breadcrumb-item>
        </el-breadcrumb>
        </div>
    </section>
    <section class="nybox">
        <div class="am-container">
            <!-- <div class="nyleft">
                <el-col>
                <p class="tit">产品中心</p>
                 <el-menu
                 default-active="2"
                    class="el-menu-vertical-demo">
            <el-menu-item v-for='(item1,index) in data' :key='index'><a @click="func1(item1)">{{item1.resultMsg}}</a></el-menu-item>

                </el-menu>
  </el-col> 
            </div> -->
            <div class="nyright">
                <div class="nyproxq">
                    <div class="nyprohead">
                        <div class="thumb">
                              <div class="block">
                                <img :src="/zhcwaterweb/+this.product.furl" class="images">
                             </div>
                        </div>
                        <div class="txt" :v-model="product">
                            <h1>{{this.product.product_name}}</h1>
                            <hr>
                            <p>{{this.product.product_blurb}}</p>
                            <div class="box">
                                <a  target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=77673175&site=qq&menu=yes">在线咨询</a>

                            </div>
                        </div>
                    </div>
                    <div class="xiangxi02">       
                        <p v-html="this.product.product_details"></p>               
                        <!-- {{this.DaTa.product_details}}                      -->

                    </div>                 
                    <!-- <div class="xgnew">
                        <div class="xgcp">相关产品</div>
                        <div class="xgproli">
                            <ul class="am-avg-md-3">
                        <li v-for="(item1,index) in dataList" :key='index'>
                            <a @click="func(item1)">
                            <i> <img :src="/zhcwaterweb/+item1.furl"></i>
                            <div class="txt">
                                <p class="tit">{{item1.product_name}}</p>
                                <p class="note">{{item1.product_model}}</p>
                            </div>
                            </a>
                        </li>
                    </ul>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </section>
        <!-- <section class="homed">
            <p>有疑问或想了解更多，请咨询：15880099191</p>
        </section> -->
    </div>
</template>
<script>
import {getOne, getList} from 'api/product'
import {getTreeList}from 'api/proClass'
import { getCompanyList } from "api/company";
export default {
    data() {
        return {
            imagesbox:[
                {id:0,idView:require('../assets/images/501.jpg')},
                {id:1,idView:require('../assets/images/502.jpg')},
                {id:2,idView:require('../assets/images/503.jpg')}
            ],
            tableDate:[
                {
                    id:'1',
                    xtlb:'环境系统',
                    gn:'漏水监测',
                    gnxq:'对空调、加湿器、门口、窗户等附近进行围闭监测，有水漏出时可实时报警。'
                },
                {
                    id:'2',
                    xtlb:'',
                    gn:'温湿度监测',
                    gnxq:'实时监测动力环境内各采样点的温度和湿度参数。'
                },
                {
                    id:'3',
                    xtlb:'',
                    gn:'精密空调监测',
                    gnxq:'监测精密空调回风温度、送风温度、空调模式、运行状态、设定参数以及告警故障等信息。'
                },
                {
                    id:'4',
                    xtlb:'',
                    gn:'普通空调监测',
                    gnxq:'监测空调开关机状态及环境温湿度信息，支持红外远程开关机、模式选择、温度调节、风速调节等操作。'
                },
                {
                    id:'5',
                    xtlb:'',
                    gn:'新风机监测',
                    gnxq:'监测新风机开关机及运行状态。'
                },
                {
                    id:'6',
                    xtlb:'动力系统',
                    gn:'配电监测',
                    gnxq:'实时监测动力环境的供电质量，包含供电电压、电流、功率因数、频率、有功功率、无功功率、视在功率、电能等。'
                },
                {
                    id:'7',
                    xtlb:'环境系统',
                    gn:'漏水监测',
                    gnxq:'对空调、加湿器、门口、窗户等附近进行围闭监测，有水漏出时可实时报警。'
                },
                {
                    id:'8',
                    xtlb:'',
                    gn:'UPS监测',
                    gnxq:'监测输入输出电压、电流、功率、后备时间等参数，整流器、逆变器、电池、旁路等部件运行状态和告警信息。'
                },
                {
                    id:'9',
                    xtlb:'',
                    gn:'市电监测',
                    gnxq:'监测单相或三相四线ABC相中任一相断电状态'
                },
                {
                    id:'10',
                    xtlb:'网络系统（注：暂不支持，开发中）',
                    gn:'路由器',
                    gnxq:'TCP服务端口网络状态'
                }
            ],
            product:[
            ],
            searchInfo: {
                page_no: 1,
                page_size: 3,
            },
            data:[],
            dataList:[],
            tel:[]
        }
    },
    methods: {
        //获取列表
    getList() {
        this.isShowloading = true;
      console.log(this.searchInfo);
      getList(this.searchInfo).then((res) => {
        console.log(res);
        this.dataList = res.data.list;
        // this.getproClass(this.dLists)
        console.log(this.dLists)
        // this.total = res.data.recordCount;
        this.isShowloading = false;
        console.log(this.modelForm)
      });
      },
      getTel(){
        getCompanyList()
        .then((res) => {
          this.tel = res.data.entity;
          console.log(this.tel)
        });
      },
      //获取类型列表
    getTreeList() {
        this.isShowloading = true;
         console.log(this.searchInfo);
        getTreeList(this.searchInfo).then((res) => {
        console.log(res);
        this.data = res.data.list;
        console.log(this.data)
      });},
      //获取产品详情
        getOne() {
            let id = this.$route.query.id
            getOne({fuid: id}).then((res) => {
                this.product = res.data.entity
            })
        },
        
        func1(item1) {
        this.$router.push({name: 'productClass',params:{ product_class:item1.resultMsg}});
        },

        func(item) {
         let id = item.fuid;
         this.$route.params.id = item.fuid
        getOne({fuid: id}).then((res) => {
            this.product = res.data.entity
        })
      },
    },
    mounted() {
         this.getOne()

    },
    computed:{
        fuid() {
            return this.$store.fuid
        }
    },
    created() {
        this.getList()
        this.getTreeList()
        this.getTel()
    }
}
</script>
<style lang="scss" scoped>
.trbaner {
    position: relative;
}
.trbaner img {
    max-width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align: middle;
    border: 0;
}
.trbaner .foxj {
    position:absolute;
    width: 100%;
    height: 100%;
    z-index: 22;
}
.am-container {
    position: relative;
    height: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    max-width: 1000px;
}
.foxj .txt {
    position: absolute;
    text-align: left;
    right: 1.5rem;
    height: 100%;
    display: table-cell;
    width: 46%;
}
.foxj .txt .tit{
    font-size: 32px;
    margin-bottom: 15px;
    color: #fff;
}
.foxj .note{
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 2;
    color: #fff;
}
.am-container::after{
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.foxj .txt .am-vertical-align-middle {
       position: relative;
       top: 30%;
}
.am-path {
    height: 50px;
    .el-breadcrumb {
    border: 1px solid #eee;
    font-size: 14px;
    line-height: 50px;
    height: 50px;
    padding-left: 20px;
}
}
.nybox {
    padding: 30px 0;
    background-color: #f5f5f5;
    .nyleft {
        float: left;
        width: 254px;
        .tit{
            font-size: 22px;
            padding: 15px 0 15px 25px;
            background: #00579d;
            color: #fff;
        }
        .el-submenu {
            background-color: #f5f5f5;
            margin: 6px 10px;
        }
    }
    .nyright {
        width: 100%;
        float: right;
        background-color: #fff;
        .nyproxq {
            height: 100%;
        }
        .nyproxq::after{
               content: ".";
                display: block;
                height: 0;
                clear: both;
                visibility: hidden;
                -webkit-box-sizing: border-box;
                 box-sizing: border-box; 
        }        
        .nyprohead::after{
               content: ".";
                display: block;
                height: 0;
                clear: both;
                visibility: hidden;
                -webkit-box-sizing: border-box;
                 box-sizing: border-box; 
        }
        .nyprohead {
            padding: 30px;
            display: block;
            height: 100%;
            .thumb {
                width: 48%;
                float: left;
                display: block;
                img{
                    z-index: 1;
                    width: 100%;
                    height: 282px;
                }
            }
            .txt {
                width: 48%;
                padding: 15px;
                float: right;
                p {
                    margin: 10px 0;
                    font-size: 16px;
                    text-align: left;
                    line-height: 20pxpx;
                }
                .box {
                    margin-top: 30px;
                    width: 100%;
                    .phone {
                        background:url('~images/QQ.png') no-repeat left center; 
                        float: left;
                        padding-left: 42px;
                        margin: 6px 0 0 15px;
                        span {
                                display: block;
                                font-size: 13px;
                                color: #666666;
                                float: left;
                        }
                        em {
                                display: block;
                                font-size: 24px;
                                color: #f1251f;
                                font-family: arial;
                                line-height: 24px;
                        }
                    }
                }
                a {
                    display: block;
                    width: 172px;
                    height: 53px;
                    float: left;
                    font-size: 20px;
                   color: #fff;
                    line-height: 53px;
                    background: #00579d;
                   border-radius: 50px;
                    text-align: center;
                   text-decoration: none;
                }
            }
        }
        .xiangxi02 {
            clear: both;
            overflow: hidden;
            padding: 0 30px 30px 30px;
            text-align: left;
            .hd2 {
                background: url('~images/icon_po.png') 15px center no-repeat #f5f5f5;
                    margin-bottom: 25px;
                    font-size: 22px;
                    font-weight: 400;
                    line-height: 60px;
                    padding-left: 50px;
            }
            .xiangxi03 {
                    color: #333;
                    line-height: 2;
                    overflow: hidden;
                }

        }
        .xgcp {
                padding: 15px 0;
                margin: 0 30px;
                font-size: 22px;
                border-bottom: 1px solid #eee;
                text-align: left;
        }
        .xgproli {
            padding: 15px;
                 ul {
                    display: -webkit-flex;
                    display: flex;
                    align-content: space-around;
                    -webkit-flex-wrap: wrap;
                    flex-wrap: wrap;
                    -webkit-align-content: center;
                    align-content: center;
                    justify-content: space-between;
                    li {
                    padding:0 10px ;
                    list-style: none;
                    width: 30%;
                    a{
                        display: block;
                        background: #fff;
                        text-align: center;
                        border: 1px solid #fff;
                        overflow: hidden;
                        i {
                            overflow: hidden;
                        }
                        img {
                            max-width: 100%;
                            transition: all 0.7s;
                            height: 175px;
                        }
                        img:hover {
                            transform: scale(1.2) ;
                        }
                        .txt{
                            padding:20px;
                        .tit {
                                font-size: 16px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                margin-bottom: 4px;
                                color: #333;
                        }
                        .note {
                                font-size: 12px;
                                margin-bottom: 6px;
                                color: #999;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 2;
                                overflow: hidden;
                        }}
                    }
                    a:hover {
                        border: 1px solid #0e90d2
                    }
                }
                        }
                }
    }
}
.homed {
    text-align: center;
    font-size: 24px;
    color: #fff;
    padding: 3% 0;
    background: url('~images/adbg.jpg') center no-repeat;
    background-size: cover;
}
</style>