import axios from 'axios';
let baseUrl = '/zhcwaterweb';
var qs = require('qs');  



//获取类型
export const getTreeList = params=>{
    let pm=qs.stringify(params);
    return axios.post(`${baseUrl}/mweb/productClass/loadClassTree`,pm);
}